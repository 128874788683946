@use "src/_app/styles/mixins.scss" as *;
.app_banner {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  text-align: start;
  border-radius: 24px;
  padding: 0 44px 44px;
  color: var(--primary);
  background-color: var(--bg_mercuryo_banner);
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50px 50%;

  @include respond(laptop) {
    background-position: 0 50%;
  }

  @include respond(tablet) {
    background-position: bottom center;
    align-items: flex-start;
    justify-content: center;
    padding: 22px 41px;
  }

  &.long_lang {
    @include respond(tablet) {
      padding: 22px 16px;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    align-items: flex-start;
    justify-content: space-around;
    max-width: 360px;
    position: relative;
    z-index: 2;

    &__text {
      margin-top: 139px;

      @include respond(tablet) {
        margin-top: 24px;
      }

      &_kk {
        margin-top: 70px;

        @include respond(tablet) {
          margin-top: 24px;
        }
      }
    }

    @include respond(laptop) {
      max-width: 330px;
      row-gap: 12px;
    }

    @include respond(tablet) {
      max-width: 100%;
      flex-direction: column;
      align-items: center;
      text-align: center;
      row-gap: 8px;
    }

    .content__title {
      @include tTitle_1_main;
      margin: 0 0 14px;

      @include respond(laptop) {
        font-size: 44px;
        line-height: 56px;
      }

      @include respond(tablet) {
        font-size: 28px;
        line-height: 34px;
      }
    }

    .content__descr {
      @include tTitle_2;
      margin: 0 0 32px;

      @include respond(laptop) {
        font-size: 22px;
      }

      @include respond(tablet) {
        @include tSubtitle;
        margin: 0 0 8px;
      }
    }
  }

  .btn {
    width: 191px;
    margin-bottom: 44px;
  }

  &__image {
    position: absolute;
    inset-inline-start: 220px;
    bottom: 0;
    width: 1063px;

    @include respond(laptop) {
      inset-inline-start: 50px;
    }

    @include respond(tablet) {
      width: 330px;
      height: 327px;
      inset-inline-start: 50%;
      transform: translateX(-50%);
    }

    @include respond(tabletS) {
      bottom: -36px;
    }

    &.long_lang {
      @include respond(tablet) {
        bottom: -12px;
      }
    }
  }

  .image_ar {
    inset-inline-start: 360px;

    @include respond(laptop) {
      inset-inline-start: 290px;
    }
  }
}
