@use "src/_app/styles/mixins.scss" as *;
.accept__banner_wrapper {
  position: relative;
  border-radius: 24px;
  height: 100%;
  background-color: var(--secondary);
  background-image: url('/img/banners/accept-banner/desktop-bg.png');
  background-size: auto;
  background-position: right;
  background-repeat: no-repeat;
  direction: ltr;

  @include respond(laptopL) {
    background-position-x: 400px;
  }

  @include respond(laptopC) {
    background-position-x: 200px;
  }

  @include respond(laptop) {
    background-image: url('/img/banners/accept-banner/laptop-bg.png');
  }

  @include respond(tabletL) {
    background-size: cover;
    background-position-x: 110px;
  }

  @include respond(tablet) {
    background-image: url('/img/banners/accept-banner/mobile-bg.png');
    background-position: center bottom;
    background-size: auto;
  }

  &_long_lang {
    @include respond(tablet) {
      background-position-y: 250px;
    }
  }

  &_dark {
    background-image: url('/img/banners/accept-banner/desktop-bg-dark.png');

    @include respond(laptopL) {
      background-position-x: 245px;
    }

    @include respond(laptopC) {
      background-image: url('/img/banners/accept-banner/laptop-bg-dark.png');
      background-position-x: 90px;
    }

    @include respond(tabletL) {
      background-position-x: 0;
    }

    @include respond(tablet) {
      background-image: url('/img/banners/accept-banner/mobile-bg-dark.png');
      background-position: center;
      background-position-y: 0;
    }

    &_long_lang {
      @include respond(tablet) {
        background-position-y: 64px;
      }
    }
  }

  .content {
    padding: 74px 60px 52px 44px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50%;
    text-align: left;

    @include respond(laptop) {
      max-width: 45%;
      padding: 92px 40px 52px;
    }

    @include respond(tabletL) {
      padding: 26px 40px;
      max-width: 55%;
    }

    @include respond(tablet) {
      padding: 26px 24px;
      align-items: center;
      text-align: center;
      max-width: 100%;
    }

    @include respond(tabletS) {
      padding: 32px 24px 8px;
    }

    .title {
      @include tTitle_1_main;
      margin: 0 0 14px;
      color: var(--primary);

      @include respond(laptop) {
        font-size: 44px;
        line-height: 56px;
        margin-bottom: 12px;
      }

      @include respond(tablet) {
        padding-top: 28px;
        font-size: 28px;
        line-height: 34px !important;
        margin-bottom: 8px;
      }

      @include respond(tabletS) {
        padding-top: 0;
      }

      &__veryLong_lang {
        line-height: 46px;
      }
    }

    .description {
      @include tTitle_2;

      margin: 0 0 75px;
      color: var(--primary);

      @include respond(laptop) {
        font-size: 22px;
      }

      @include respond(tablet) {
        font-size: 16px !important;
        line-height: 24px !important;
        margin-bottom: 0;
      }

      &__veryLong_lang {
        line-height: 30px;
      }
    }
  }

  .long_lang {
    @include respond(laptopL) {
      padding: 30px 34px 50px 40px;
    }

    @include respond(tabletL) {
      padding: 26px 26px 26px 40px;
      max-width: 45%;
    }

    @include respond(tablet) {
      padding: 26px 24px;
      align-items: center;
      text-align: center;
      max-width: 100%;
    }

    @include respond(tabletS) {
      padding: 32px 24px 8px;
    }
  }

  .veryLong_lang {
    @include respond(tabletL) {
      padding: 20px 26px 26px 40px;
    }
  }

  .buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 16px;
    position: absolute;
    bottom: 52px;

    a {
      height: 56px;
    }

    @include respond(laptopL) {
      bottom: 40px;
    }

    @include respond(laptop) {
      display: none;
    }
  }
}
