@use "src/_app/styles/mixins.scss" as *;
.body {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  overflow: hidden;

  .subTitle {
    @include tTitle_3-semibold;
    color: var(--primary);
    margin: 0;

    @include respond(laptopC) {
      font-size: 16px;
    }
  }

  .text_desktop {
    @include respond(tablet) {
      display: none;
    }
  }

  .text_mobile {
    display: none;

    @include respond(tablet) {
      display: inline;
    }
  }

  .text {
    @include tSubtitle;
    color: var(--primary);
    margin: 0;

    @include respond(laptopC) {
      font-size: 14px;
      letter-spacing: -0.08px;
    }

    &:last-child {
      margin-bottom: 8px;

      @include respond(tablet) {
        margin-bottom: 24px;
      }
    }
  }
}
