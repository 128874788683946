@use "src/_app/styles/mixins.scss" as *;
.border__wrapper {
  background: var(--border_7);
  border-radius: 24px;
}

.block__wrapper {
  display: flex;
  flex-direction: column;

  height: 478px;
  background: var(--bg_16);
  box-shadow: var(--shadow_3);

  border-radius: 24px;

  align-items: center;
  justify-content: center;

  @include respond(tablet) {
    height: 316px;
  }

  img {
    margin-bottom: 32px;

    @include respond(tablet) {
      margin-bottom: 24px;
    }
  }

  .footer {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
      color: var(--text_1);
      margin: 0 0 4px;

      @include tCaption;
    }

    .code {
      margin: 0;
      color: var(--primary);

      @include tCaption;
    }
  }
}
