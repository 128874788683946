@use "src/_app/styles/mixins.scss" as *;
.block__wrapper {
  display: grid;
  grid-template-columns: minmax(286px, 558px) 578px;
  column-gap: 48px;
  padding: 48px;
  border-radius: 24px;
  background: var(--bg_13);

  @include respond(laptopC) {
    grid-template-columns: 286px 1fr;
    column-gap: 32px;
    padding: 32px;
  }

  @include respond(tabletL) {
    grid-template-columns: 200px 1fr;
  }

  @include respond(tablet) {
    grid-template-columns: 1fr;
    background: var(--bg_18);
    padding: 0;
  }
}

.accordion__wrapper {
  display: flex;
  flex-direction: column;
}

.accordion__content_mobile {
  display: none;

  @include respond(tablet) {
    display: block;
  }
}

.accordion__content_desktop {
  @include respond(tablet) {
    display: none;
  }
}
