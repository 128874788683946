@use "src/_app/styles/mixins.scss" as *;
.content_mobile {
  display: none;

  @include respond(tablet) {
    display: inline;
  }
}

.content_desktop {
  @include respond(tablet) {
    display: none;
  }
}

.accordionContent_wrapper {
  @include respond(tablet) {
    padding-bottom: 8px;
  }
}
