@use "src/_app/styles/mixins.scss" as *;
.accordion__row {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.header__wrapper {
  padding: 24px 0 16px;
  border-bottom: 1px solid var(--border_6);
  cursor: pointer;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;

  @include respond(tablet) {
    padding: 0 16px;
    border-bottom: 1px solid transparent;
  }

  .header__top__wrapper {
    @include respond(tablet) {
      padding: 16px 0;
      border-bottom: 1px solid var(--border_6);
    }
  }

  .header__top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    svg path {
      fill: var(--primary);
    }

    .icon__minus path {
      stroke: var(--primary);
    }

    @include respond(tabletS) {
      pointer-events: none;
    }
  }

  .title {
    @include tTitle_2_semibold;
    color: var(--primary);
    margin: 0 0 8px;

    @include respond(laptopC) {
      font-size: 18px;
    }
  }
}
