@use "src/_app/styles/mixins.scss" as *;
.block__wrapper {
  box-shadow: 0 0 80px rgb(0 0 0 / 10%);
  border-radius: 24px;

  @include respond('tablet') {
    box-shadow:
      0 4px 29px rgb(0 0 0 / 9%),
      0 0 12px rgb(37 33 41 / 4%);
  }
}

.header__wrapper {
  display: flex;
  flex-direction: column;

  background: var(--bg_14);
  border-radius: 24px 24px 0 0;
  padding: 32px;

  @include respond(tablet) {
    padding: 16px;
  }
}

.warning {
  &__block {
    margin-top: 32px;
  }

  &__text {
    margin: 0;

    @include text_regular;
    color: var(--primary);

    span {
      background: #ffa64d;
      border-radius: 37px;
      padding: 1px 10px;
      white-space: nowrap;
    }
  }
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background: var(--secondary);
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  &__btn {
    padding: 18px;
    width: 100%;
    border: none;
    background: var(--primary);
    border-radius: 10px;

    display: flex;
    align-items: center;
    justify-content: center;

    p {
      color: var(--secondary);
      margin: 0;

      @include tButton_2;

      display: flex;
      align-items: center;
      column-gap: 7px;

      &:first-child {
        padding-right: 12px;
        margin-right: 12px;
        border-right: 1px solid var(--border_5);
      }

      svg path {
        fill: var(--secondary);
      }
    }
  }

  @include respond(tablet) {
    padding: 16px;
  }
}
