@use "src/_app/styles/mixins.scss" as *;
.accordion {
  &__container {
    flex-direction: column;
    display: flex;
    max-width: 1280px;
    row-gap: 56px;
    margin: 90px auto 0;
    padding-bottom: 90px;
    border-bottom: 1px solid var(--border_6);

    @include respond(tablet) {
      margin: 44px auto 42px;
      row-gap: 32px;
      padding: 0;
      border-bottom: none;
    }
  }

  &__header {
    position: relative;
    margin: 0 auto;
    text-align: center;
    max-width: 812px;
    color: var(--primary);

    @include tTitle_1_main;

    @include respond(tablet) {
      font-size: 28px;
      line-height: 32px;
      padding: 0 16px;
    }

    @include respond(mobileL) {
      text-align: left;
    }
  }
}
