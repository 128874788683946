@use "src/_app/styles/mixins.scss" as *;
.caption_hint {
  background: var(--mainBackgroundSecondary);
  border-radius: 12px;
  display: flex;
  padding: 12px 12px 16px;
  align-items: center;

  .hint__description {
    @include tSubtitle;
    color: var(--mainPrimary);
  }

  &.warning {
    background-color: var(--backgroundAlpha_Warning_2);
    align-items: flex-start;

    .hint__title {
      color: var(--primary);

      margin-bottom: 6px;

      @include tTitle_3-demibold;

      @include respond(tablet) {
        font-size: 16px;
      }
    }

    .hint__icon {
      color: var(--warning);
    }
  }

  &.error {
    background-color: var(--backgroundAlpha_Negative_1);
    align-items: flex-start;
    margin-top: 10px;

    .hint__title {
      margin-bottom: 6px;

      @include tTitle_3-demibold;
      color: var(--contentPrimary);
    }

    .hint__icon {
      color: var(--negative);
    }
  }

  &.success {
    background-color: var(--backgroundAlpha_Success_1);

    .hint__title {
      margin-bottom: 6px;

      @include tTitle_3-demibold;
      color: var(--contentPrimary);
    }

    .hint__icon {
      color: var(--success);
    }
  }

  &.notice {
    background-color: var(--backgroundSecondary);
    align-items: flex-start;

    .hint__title {
      margin-bottom: 6px;

      @include tTitle_3-demibold;
      color: var(--contentPrimary);
    }

    .hint__icon {
      color: var(--iconHover);
    }
  }

  .head__wrapper {
    display: flex;
    flex-direction: row;
  }

  .hint__icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    border-radius: 16px;

    @include respond(tablet) {
      margin-right: 15px;
    }
  }

  &.hideBg {
    background: none;
    padding: 0;
  }
}
